// <a class="btn btn-social text-center  text-white btn-facebook" onclick="facebook_share()"><i class="fa-brands fa-facebook"></i> Facebook</a>
// 
// window.facebook_share = (res) => {
//     FB.ui({
//         display: 'popup',
//         method: 'share',
//         href: 'https://tax.ensoulify.com/code-finder?fb_ref=top_left',
//         // href: 'https://www.facebook.com/dialog/share?app_id=1251175568960497&display=popup&href=&fb_source=profile_oneline&redirect_uri=https://tax.ensoulify.com/fb_test',
//         redirect_uri: 'https://tax.ensoulify.com/fb_final'

//     },
//         // callback
//         function (response) {
//             if (response && !response.error_message) {
//                 // then get post content
//                 frappe.call({
//                     method: 'accounting.tenant.doctype.subscription.subscription.set_shared_flag',
//                     freeze: false,
//                     callback: (r) => {
//                         console.log(r);
//                         frappe.show_alert({ message: "<h5>تم اضافة استخدامات اكتر في حسابك</h5>", indicator: 'green' }, 10)
//                         setTimeout(() => {
//                             cur_dialog.hide()
//                         }, 4000);

//                     }
//                 })
//                 // share_popup
//                 // alert('successfully posted. Status id : ' + response.post_id);
//             } else {
//                 frappe.show_alert({ message: "<h5>برجاء المحاول مرة اخري</h5>", indicator: 'red' }, 10)
//                 setTimeout(() => {
//                     cur_dialog.hide()
//                 }, 4000);
//                 // alert('Something went error.');
//             }
//         });

// }




window.generate_referral_code = ()=>{
    // then get post content
    email = frappe.user_id
    //  is Currant user id is email ? 
    if( validate_email(frappe.user_id)){

        frappe.call({
            method: 'accounting.referral.doctype.referrer.referrer.generate_referral_code',
            freeze: true,
            args: { "email": email },
        callback: (r) => {
            console.log(r.message);
            code = r.message 
            route = `https://tax.ensoulify.com/m/?ref=${ code  }`
            // frappe.show_alert({ message: "<h5>تم اضافة استخدامات اكتر في حسابك</h5>", indicator: 'green' }, 10)
            // setTimeout(() => {
                //     cur_dialog.hide()
                // }, 4000);
            }
        })
        
    }
}



document.addEventListener("DOMContentLoaded", function(event) { 

window.social_share = () => { 
    // Uses sharer.js 
    //  https://ellisonleao.github.io/sharer.js/#twitter	
       var url = window.location.href;
       var title = document.title;
       var subject = "Read this good article";
       var via = "yourTwitterUsername";
       //console.log( url );
       //console.log( title );
    
    //facebook
    $('#share-wa').attr('data-url', url).attr('data-title', title).attr('data-sharer', 'whatsapp');
    //facebook
    $('#share-fb').attr('data-url', url).attr('data-sharer', 'facebook');
    //twitter
    $('#share-tw').attr('data-url', url).attr('data-title', title).attr('data-via', via).attr('data-sharer', 'twitter');
    //linkedin
    $('#share-li').attr('data-url', url).attr('data-sharer', 'linkedin');
    // google plus
    $('#share-gp').attr('data-url', url).attr('data-title', title).attr('data-sharer', 'googleplus');
      // email
      $('#share-em').attr('data-url', url).attr('data-title', title).attr('data-subject', subject).attr('data-sharer', 'email');
    
    //Prevent basic click behavior
    $( ".sharer button" ).click(function() {
      event.preventDefault();
    });
      
      
    // only show whatsapp on mobile devices  
    var isMobile = false; //initiate as false
    // device detection
    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) { 
        isMobile = true;
    }
    
    if ( isMobile == true ) {
    $("#share-wa").hide();
    }
    
}  
    });
