import "frappe/public/js/frappe/ui/chart.js";

// import "./js/hope-ui";
import "./accounting/error";
// console.log("createApp", createApp );


window.toCamel = (s) => {
  return s.replace(/([-_][a-z])/ig, ($1) => {
    return $1.toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};

window.load_assets = (path)=>{
  var _img = document.getElementById('id1');   
   var newImg = new Image;
   newImg.src = window.location.origin + path ;
 }


 //Get the button
let mybutton = document.getElementById("btn-back-to-top");

// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function () {
  scrollFunction();
};

function scrollFunction() {
  if (
    document.body.scrollTop > 150 ||
    document.documentElement.scrollTop > 150
  ) {
    mybutton.style.display = "block";
  } else {
    mybutton.style.display = "none";
  }
}
// When the user clicks on the button, scroll to the top of the document
mybutton.addEventListener("click", backToTop);

function backToTop() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}


window.validate_mobile = ( txt ) => {
    var phoneno = /^[\+]?\d{11,15}$/;
    if(txt.match(phoneno))
          {
        return true;
          }
        else
          {
          return false;
          }
};
